import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/';
import ReactPlayer from 'react-player';
import '../../../node_modules/video-react/dist/video-react.css';

const useStyles = makeStyles(() => ({
  video_container: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  video_player: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
}));

export const CCMVideo = ({ poster, src }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.video_container}
      data-testid={`video-container-${poster}-${src}`}
    >
      <ReactPlayer
        className={classes.video_player}
        light={poster}
        url={src}
        controls
        playsinline
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default CCMVideo;

CCMVideo.propTypes = {
  poster: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};
