// Reassigning paramters intentially in this file
/* eslint-disable no-param-reassign */
import React from 'react';
import { Typography, Link } from '@material-ui/core';
import RedButton from '../../components/RedButton/RedButton';
import CCMVideo from '../../components/CCMVideo/CCMVideo';

import Image from '../../components/Image';
/**
 This function takes the nasty WordPress components and maps 
 them to the existing components in the Cancer Care React App
 
 @param {object} domNode - (coming from wordpress)
 */
export default (domNode) => {
  if (!domNode.attribs) return domNode;
  if (domNode.name.match(/h[1-4]/)) {
    return (
      <Typography variant={domNode.name}>{domNode.children[0].data}</Typography>
    );
  }
  switch (domNode.attribs.class) {
    case 'wp-block-button':
      return (
        <RedButton component={Link} href={domNode.children[0].attribs.href}>
          {domNode.children[0].children[0].data}
        </RedButton>
      );
    case 'wp-block-embed__wrapper':
      return <CCMVideo poster="" src={domNode.children[1].attribs.src} />;

    case 'wp-block-columns': {
      /**
       Layout logic only reassigns the mobile and desktop 
       attribs.style properties and returns the domNode, 
       so that child domNodes are not lost
      */
      const windowWidth =
        typeof window !== 'undefined' ? window.innerWidth : '';
      if (windowWidth < 960) {
        domNode.attribs.style = 'display:flex; flex-direction:column';
      } else {
        domNode.attribs.style = 'display:flex; margin: 0 -20px';
        domNode.children.forEach((child) => {
          if (child.attribs && child.attribs.class === 'wp-block-column') {
            if (child.attribs.style === 'flex-basis:33.33%') {
              child.attribs.style = 'flex-basis:33.33%; padding: 20px';
            } else if (child.attribs.style === 'flex-basis:66.66%') {
              child.attribs.style = 'flex-basis:66.66%; padding: 20px';
            } else {
              child.attribs.style = 'flex-basis:50%; padding: 20px';
            }
          }
        });
      }
      return domNode;
    }
    case 'wp-block-image size-large':
      return (
        <Image
          src={domNode.children[0].attribs.src}
          alt={domNode.children[0].attribs.alt}
        />
      );
    default:
      return domNode;
  }
};
