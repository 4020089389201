import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
    height: '35px',
    border: `1px solid ${theme.palette.coreRed}`,
    background: 'none',
    textDecoration: 'none',
    '&:hover': {
      border: `3px solid ${theme.palette.coreRed}`,
      background: 'none',
      textDecoration: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    borderRadius: '40px',
    [theme.breakpoints.up('md')]: {
      padding: '0 15px',
    },
  },
  label: {
    color: theme.palette.coreRed,
    fontSize: '14px',
    margin: 0,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
}));

const RedButton = (props) => {
  const { children, setref } = props;

  const classes = useStyles();

  return (
    <Button ref={setref} className={classes.root} {...props}>
      <span className={classes.label}>{children}</span>
    </Button>
  );
};

export default RedButton;

RedButton.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setref: PropTypes.object,
};

RedButton.defaultProps = {
  setref: undefined,
};
