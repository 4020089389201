import React from 'react';
import { makeStyles } from '@material-ui/styles';
import propTypes from 'prop-types';

const useStyles = makeStyles({
  image: {
    width: '100%',
    objectFit: 'cover',
  },
  bannerImage: {
    maxHeight: '375px',
  },
});

const Image = ({ src, alt, banner }) => {
  const classes = useStyles();
  const imageClasses = banner
    ? `${classes.image} ${classes.bannerImage}`
    : classes.image;
  return <img src={src} alt={alt} className={imageClasses} />;
};

Image.propTypes = {
  src: propTypes.string.isRequired,
  alt: propTypes.string.isRequired,
  banner: propTypes.bool,
};

Image.defaultProps = {
  banner: false,
};
export default Image;
